"use client";
import Image from "next/image";
import React, { useState, useEffect } from "react";
import { Accordion, Button, Col, Form, Offcanvas, Row } from "react-bootstrap";
import { filterClearIcon, search, searchLight } from "../../public/icons";
import styles from "../styles/SideFilters.module.css";
import SideFiltersLoader from "./SideFiltersLoader";
import { usePathname, useRouter, useSearchParams } from "next/navigation";

const SideFilters = ({
  filtersData,
  isLoading,
  dependentFilters,
  setdependentFilters,
  orderBy,
  setCatCahnge,
  setisSandwichOpen,
  isSandwichOpen,
  setOrderBy,
  filters,
  setfiltersData,
  removeDependentFiltersParam,
  search,
  setSearch,
  priceRange,
  setPriceRange,
  filterINDFilters,
  Dparams,
  seeMoreFilters,
  catId,
  specs,
}: any) => {
  // const [search, setSearch] = useState<string>("");
  // const [priceRange, setPriceRange] = useState<any>();
  // const [filtersData, setfiltersData] = useState<any>([]);

  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const [minPrice, setMinPrice] = useState<number>();
  const [maxPrice, setMaxPrice] = useState();
  const [validate, setValidate] = useState(false)

  useEffect(() => {
    // filtersCheckedData(filtersData,dependentFilters,search,priceRange)
  }, [filtersData, dependentFilters, search, priceRange, orderBy]);
  // const data=await queryClient.getQueryData("todos")
  const handleCheckboxChange = (e: any, val: any, id: any) => {
    setCatCahnge(true);
    const newValues = { ...filtersData };
    const updated = newValues[val]?.values?.map((item: any, i: any) => {
      if (i === id && newValues[val].inputType === "checkbox") {
        return { ...item, isChecked: e.target.checked };
      } else if (newValues[val].inputType === "radio") {
        if (i === id) {
          return { ...item, isChecked: e.target.checked };
        } else {
          return { ...item, isChecked: false };
        }
      }
      return item;
    });
    setfiltersData({
      ...newValues,
      [val]: { ...newValues[val], values: updated },
    });
  };
  const handleChangeDependentFilter = (e: any, val: any, id: any) => {
    setCatCahnge(true);
    setdependentFilters((prev) => {
      const updated = prev[val]?.values?.map((item: any, i: any) => {
        if (i === id && prev[val].inputType === "checkbox") {
          return { ...item, isChecked: e.target.checked };
        } else if (prev[val].inputType === "radio") {
          if (i === id) {
            return { ...item, isChecked: e.target.checked };
          } else {
            return { ...item, isChecked: false };
          }
        }
        return item;
      });
      return { ...prev, [val]: { ...prev[val], values: updated } };
    });

    // let val={...dependentFilters}
    // val[e.target.name][e.target.id].isChecked = e.target.checked;
    // setdependentFilters(val)
  };
  const clearAllFilters = () => {
    // router.replace(pathname);
    setCatCahnge(true);
    setSearch("");

    if (filtersData) {
      setfiltersData((prev: any) => {
        return Object.entries(prev).reduce(
          (oldFilters: any, [fKey, fValue]: any) => {
            return {
              ...oldFilters,
              [fKey]: {
                ...fValue,
                values: fValue.values.reduce((oldValue: any, value: any) => {
                  return [...oldValue, { ...value, isChecked: false }];
                }, []),
              },
            };
          },
          {}
        );
      });
    }
    if (priceRange) {
      setCatCahnge(true);
      setPriceRange({});
      setMaxPrice("");
      setMinPrice("");
    }
    if (dependentFilters) {
      setdependentFilters((prev: any) => {
        return Object.entries(prev).reduce(
          (oldFilters: any, [fKey, fValue]: any) => {
            return {
              ...oldFilters,
              [fKey]: {
                ...fValue,
                values: fValue.values.reduce((oldValue: any, value: any) => {
                  return [...oldValue, { ...value, isChecked: false }];
                }, []),
              },
            };
          },
          {}
        );
      });
    }
    setOrderBy({ price: "" });
  };
  const handlePriceChange = (min: number, max: number) => {

    setCatCahnge(true);
    setPriceRange(min && max ? { from: min, to: max } : {});
  };
  const clearSingleFilter = (filter: any) => {
    setCatCahnge(true);
    setfiltersData({
      ...filtersData,
      [filter]: filtersData?.[filter]?.map((item: Object) => ({
        ...item,
        isChecked: false,
      })),
    });
  };

  const handleSubmitPrice = (e: any) => {
    e.preventDefault();


    setValidate(true)
    if (minPrice > 0 && maxPrice > 0 && minPrice < maxPrice) {
      setCatCahnge(true);

      setPriceRange(minPrice && maxPrice ? { from: minPrice, to: maxPrice } : {});

    } else {
      return
    }

  }
  const clearInDependentFilter = (filter: any) => {
    setCatCahnge(true);

    setfiltersData((prevState: any) => {
      const updatedState: any = {};
      for (const category in prevState) {
        if (prevState.hasOwnProperty(category)) {
          updatedState[category] = prevState[category].values.map(
            (item: any) => ({ ...item, isChecked: false })
          );
        }
      }
      return {
        ...prevState,
        [filter]: { ...prevState[filter], values: updatedState[filter] },
      };
    });
  };
  const clearDependentFilter = (filter: any) => {
    setCatCahnge(true);

    setdependentFilters((prevState: any) => {
      const updatedState: any = {};
      for (const category in prevState) {
        if (prevState.hasOwnProperty(category)) {
          updatedState[category] = prevState[category].values.map(
            (item: any) => ({ ...item, isChecked: false })
          );
        }
      }
      return {
        ...prevState,
        [filter]: { ...prevState[filter], values: updatedState[filter] },
      };
    });
  };
  const removeDisallowedAndConsecutiveChars = (input: string): string => {
    const disallowedCharsRegex =
      /\/{2,}|(\@|\#|\$|\%|\^|\&|\*|\,|\!|\:|\;)+|(?!\d)\/(?!\d)/g;

    let cleanedInput = input.replace(disallowedCharsRegex, "");

    cleanedInput = cleanedInput.replace(/\++/g, "+");

    cleanedInput = cleanedInput.replace(/\s+/g, " ");

    cleanedInput = cleanedInput.replace(/\(\s*[^A-Za-z0-9]*\s*\)/g, "");

    cleanedInput = cleanedInput
      .replace(/\'{2,}/g, "''")
      .replace(/\s'+\s/g, "")
      .replace(/(?![a-z])'(?=[a-z])/g, "")
      .replace(/'+(?=\w|\d)/g, "");

    cleanedInput = cleanedInput.replace(/\s{2,}/g, " ");
    const trailingSpace = input.endsWith(" ") ? " " : "";

    return cleanedInput.trim() + trailingSpace;
  };

  const debounce = (mainFunction: Function, delay: number) => {
    // Declare a variable called 'timer' to store the timer ID
    let timer: NodeJS.Timeout;
    // Return an anonymous function that takes in any number of arguments
    return function (min: number, max: number) {
      // Clear the previous timer to prevent the execution of 'mainFunction'
      clearTimeout(timer);
      // Set a new timer that will execute 'mainFunction' after the specified delay
      timer = setTimeout(() => {
        mainFunction(min, max);
      }, delay);
    };
  };

  // Create a new debounced version of the 'searchData'
  const debouncedSearchData = debounce(handlePriceChange, 1000);

  // Call the debounced version of 'searchData'
  return (
    <div>
      {" "}
      {false ? (
        <SideFiltersLoader />
      ) : (
        <>
          <div
            className={`${styles.sideFilters}  border rounded-2 d-lg-block d-md-none d-none me-4`}
          >
            <div className={`${styles.search_filter} p-2 pb-3 `}>
              <div className="d-flex mb-1 ">
                <Form.Control
                  value={search}
                  onChange={(e) => {
                    setCatCahnge(true);
                    setSearch(
                      removeDisallowedAndConsecutiveChars(e.target.value)
                    );
                  }}
                  className=" rounded-0 fs-14 rounded-start border-0  w-100"
                  placeholder="Search by keyword(s)"
                />
                <Button className=" h-100 rounded-0 rounded-end py-2 ">
                  <Image
                    src={searchLight}
                    alt="searchLight"
                    width={18}
                    height={18}
                  />
                </Button>
              </div>

              <span
                onClick={clearAllFilters}
                className="secondary-link cursor-pointer text-decoration-underline "
              >
                {" "}
                Reset Filters{" "}
              </span>
            </div>
            {filtersData && Object.keys(filtersData).length > 0 && (
              <Accordion
                alwaysOpen
                className={`${styles.accordian_main} `}
                defaultActiveKey={["0", "1", "2", "3"]}
              >
                {filtersData &&
                  Object.keys(filtersData).map((val: any, i: any) => (
                    <React.Fragment key={i}>
                      {val !== "price" && (
                        <Accordion.Item
                          key={i}
                          eventKey={
                            filtersData[val]?.values?.filter(
                              (item: any) => item.isChecked === true
                            ).length > 0
                              ? "0"
                              : i
                          }
                        >
                          <Accordion.Header className={`${styles.nav_header}`}>
                            <div className="d-flex justify-content-between w-100">
                              {filtersData[val].label
                                .toString()
                                .replaceAll("_", " ")
                                .replace(/\b\w/g, (c) => c.toUpperCase())}{" "}
                              {filtersData[val]?.values?.some(
                                (item: any) => item.isChecked
                              ) && (
                                  <span
                                    className="ms-2 text-capitalize secondary-link text-decoration-underline "
                                    onClick={() => {
                                      clearInDependentFilter(val);
                                    }}
                                  >
                                    <Image
                                      className="float-end me-2"
                                      src={filterClearIcon}
                                      alt="clearIcon"
                                      width={18}
                                      height={18}
                                    />
                                  </span>
                                )}
                            </div>
                          </Accordion.Header>
                          <Accordion.Body>
                            <div className="d-grid">
                              <div
                                className={`d-flex justify-content-between ${styles.nav_mb} px-2`}
                              >
                                <div key={`default-1`} className="mb-3">
                                  {filtersData[val].values.map(
                                    (item: any, index: any) => (
                                      <Form.Check
                                        key={index}
                                        className={`${styles.checkbox}`}
                                        onChange={(e) => handleCheckboxChange(e, val, index)}
                                        name={val}
                                        checked={item.isChecked}
                                        type={filtersData[val].inputType}
                                        id={`${index}`}
                                        label={`${item.label === 0 ? "No" : item.label === 1 ? "Yes" : item.label}${filtersData[val].unit !== null ? ` ${filtersData[val].unit}` : ""}${specs ? "" : `(${item.count})`}`}
                                      />
                                    )
                                  )}
                                </div>
                              </div>
                              {
                                <p
                                  key={i}
                                  onClick={() => {
                                    seeMoreFilters("independent", val);
                                  }}
                                  className="link fs-14 text-start ms-2 mb-0 cursor-pointer"
                                >
                                  see more
                                </p>
                              }
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      )}

                      {val === "price" && (
                        <Accordion.Item
                          eventKey={filtersData?.price ? "4" : ""}
                        >
                          <Accordion.Header className={`${styles.nav_header}`}>
                            <div className="d-flex justify-content-between w-100">

                              Prices{" "}
                              {filtersData?.price?.values?.some(
                                (range: any) => range.isSelected || maxPrice || minPrice
                              ) && (
                                  <span
                                    className="ms-2 secondary-link text-capitalize text-decoration-underline w-75"
                                    onClick={() => {
                                      setPriceRange({});
                                      setMaxPrice('');
                                      setMinPrice('');
                                    }}
                                  >
                                    <Image
                                      className="float-end me-2"
                                      src={filterClearIcon}
                                      alt="clearIcon"
                                      width={18}
                                      height={18}
                                    />
                                  </span>
                                )}
                            </div>
                          </Accordion.Header>
                          <Accordion.Body>
                            <div className="d-grid position-relative justify-content-center">
                              <div
                                className={`d-flex justify-content-between ${styles.nav_mb} mb-4 `}
                              ></div>
                              <Button
                                onClick={() => {
                                  handlePriceChange(10000, 20000);
                                }}
                                variant="outline-secondary "
                                className="mt-2"
                              >
                                Rs. 10,000 to Rs. 20,000
                              </Button>
                              <Button
                                onClick={() => {
                                  handlePriceChange(20000, 30000);
                                }}
                                variant="outline-secondary mt-2"
                              >
                                Rs. 20,000 to Rs. 30,000
                              </Button>
                              <Button
                                onClick={() => {
                                  handlePriceChange(30000, 40000);
                                }}
                                variant="outline-secondary mt-2"
                              >
                                Rs. 30,000 to Rs. 40,000
                              </Button>
                              <Button
                                onClick={() => {
                                  handlePriceChange(40000, 50000);
                                }}
                                variant="outline-secondary mt-2"
                              >
                                Rs. 40,000 to Rs. 50,000
                              </Button>
                              <Form noValidate validated={validate} onSubmit={handleSubmitPrice}>
                                <Row className="align-items-center mt-3">
                                  <Col xs="12" className="d-flex">
                                    <Form.Control
                                      type="number"
                                      placeholder="From"
                                      className="rounded-0 rounded-start "
                                      isInvalid={minPrice > maxPrice}
                                      value={minPrice}
                                      min={1}
                                      onChange={(e) =>
                                        setMinPrice(e.target.value)
                                      }
                                    />
                                    <Form.Control
                                      type="number"
                                      className="border-start-0  rounded-0"
                                      placeholder="To"
                                      value={maxPrice}
                                      isInvalid={minPrice > maxPrice}

                                      min={minPrice ? minPrice : 1}
                                      onChange={(e) =>
                                        setMaxPrice(e.target.value)
                                      }
                                    />
                                    <Button
                                      type="submit"
                                      variant="outline-secondary rounded-0 rounded-end btn-sm"

                                    >
                                      Apply
                                    </Button>
                                  </Col>
                                </Row>
                              </Form>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      )}
                    </React.Fragment>
                  ))}
              </Accordion>
            )}
            <Accordion
              alwaysOpen
              className={`${styles.accordian_main}`}
              defaultActiveKey={["0", "4", "3", "1", "2", "7", "6"]}
            ></Accordion>
          </div>
          {((dependentFilters &&
            Object.keys(dependentFilters).length > 0 &&
            filtersData?.category?.values.filter(
              (item) => item.isChecked === true
            ).length > 0) ||
            Dparams) && (
              <div
                className={` ${styles.sideFilters}  border rounded-2 d-lg-block d-md-none d-none me-4 mt-3 `}
              >
                <Accordion
                  alwaysOpen
                  className={`${styles.accordian_main} `}
                  defaultActiveKey={["0", "1", "2", "3"]}
                >
                  {dependentFilters &&
                    Object.keys(dependentFilters).map((val: any, i: any) => (
                      <React.Fragment key={i}>
                        {dependentFilters[val].values.length > 0 && (
                          <Accordion.Item
                            key={i}
                            eventKey={
                              dependentFilters[val].values.filter((item: any) => {
                                item.isChecked === true;
                              }).length > 0
                                ? "0"
                                : i
                            }
                          >
                            <Accordion.Header className={`${styles.nav_header} `}>
                              <div className="d-flex justify-content-between w-100">

                                {dependentFilters[val].label
                                  .toString()
                                  .replaceAll("_", " ")
                                  .replace(/\b\w/g, (c) => c.toUpperCase())}{" "}
                                {dependentFilters[val]?.values?.some(
                                  (item: any) => item.isChecked
                                ) && (
                                    <span
                                      className="ms-2 secondary-link text-capitalize text-decoration-underline "
                                      onClick={() => {
                                        clearDependentFilter(val);
                                      }}
                                    >
                                      <Image
                                        className="float-end me-2"
                                        src={filterClearIcon}
                                        alt="clearIcon"
                                        width={18}
                                        height={18}
                                      />
                                    </span>
                                  )}
                              </div>
                            </Accordion.Header>
                            <Accordion.Body>
                              <div className="d-grid">
                                <div
                                  className={` d-flex justify-content-between ${styles.nav_mb}  px-2`}
                                >
                                  <div key={`default-1`} className="mb-3">
                                    {dependentFilters[val].values.map(
                                      (item: any, index: any) => (
                                        <>
                                          <Form.Check
                                            key={`${item.label}-${index}`}
                                            className={`${styles.checkbox}`}
                                            onChange={(e) => {
                                              handleChangeDependentFilter(
                                                e,
                                                val,
                                                index
                                              );
                                            }}
                                            name={item.label}
                                            checked={item.isChecked}
                                            type={dependentFilters[val].inputType}
                                            id={`${item.label}-${index}`}
                                            label={`${item.label === 0
                                              ? "No"
                                              : item.label === 1
                                                ? "Yes"
                                                : item.label
                                              }${dependentFilters[val].unit !== null
                                                ? ` ${dependentFilters[val].unit}`
                                                : ""
                                              }${specs ? "" : ` (${item.count})`}`}
                                          />
                                        </>
                                      )
                                    )}
                                  </div>
                                </div>
                                {
                                  <p
                                    onClick={() => {
                                      seeMoreFilters("depenedent", val);
                                    }}
                                    className="link fs-14 text-start ms-2 cursor-pointer"
                                  >
                                    see more
                                  </p>
                                }
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        )}
                      </React.Fragment>
                    ))}
                </Accordion>
              </div>
            )}
        </>
      )}
      <div className="d-lg-none d-md-block d-sm-block d-block">
        <div className="pos-f-t">
          <Offcanvas
            backdrop={true}
            scroll={true}
            show={isSandwichOpen}
            onHide={() => setisSandwichOpen(false)}
          >
            <div className={`${styles.filter_div} pt-3 `}>
              <Offcanvas.Header closeButton>
                <h2 className={`${styles.h2}`}>Filter by:</h2>
              </Offcanvas.Header>
              <Offcanvas.Body style={{ height: "90vh" }}>
                <div className="search_filter p-4 pb-3 ">
                  <div className="d-flex mb-1 ">
                    <div
                      className={`position-relative w-100 d-flex align-items-center `}
                    >
                      <input
                        value={search}
                        onChange={(e) => {
                          setCatCahnge(true);
                          setSearch(
                            removeDisallowedAndConsecutiveChars(e.target.value)
                          );
                        }}
                        className=" rounded-0 fs-14 rounded-start w-100 h-100"
                        placeholder="Search by keyword(s)"
                      />
                    </div>
                    <Button
                      className=" h-100 px-4 rounded-0 rounded-end py-1"
                      onClick={() => setisSandwichOpen(false)}
                    >
                      <Image
                        src={searchLight}
                        alt="searchLightIcon"
                        width={18}
                        height={27}
                      />
                    </Button>
                  </div>
                </div>
                <div>
                  <p
                    onClick={() => clearAllFilters()}
                    className="text-decoration-underline link  mb-2"
                  >
                    Reset Filters
                  </p>
                </div>
                <Accordion
                  alwaysOpen
                  className={`${styles.accordian_main} `}
                  defaultActiveKey={["0", "1", "2", "3"]}
                >
                  {filtersData &&
                    Object.keys(filtersData).map((val: any, i: any) => (
                      <React.Fragment key={i}>
                        {val !== "price" && (
                          <Accordion.Item
                            key={i}
                            eventKey={
                              filtersData[val]?.values?.filter(
                                (item: any) => item.isChecked === true
                              ).length > 0
                                ? "0"
                                : i
                            }
                          >
                            <Accordion.Header
                              className={`${styles.nav_header} `}
                            >
                              <div className="d-flex justify-content-between w-100">

                                {filtersData[val].label
                                  .toString()
                                  .replaceAll("_", " ")
                                  .replace(/\b\w/g, (c) => c.toUpperCase())}{" "}
                                {filtersData[val]?.values?.some(
                                  (item: any) => item.isChecked
                                ) && (
                                    <span
                                      className="ms-2 text-capitalize secondary-link text-decoration-underline w-100"
                                      onClick={() => {
                                        clearInDependentFilter(val);
                                      }}
                                    >
                                      <Image
                                        className="float-end me-2"
                                        src={filterClearIcon}
                                        alt="clearIcon"
                                        width={18}
                                        height={18}
                                      />
                                    </span>
                                  )}
                              </div>
                            </Accordion.Header>
                            <Accordion.Body>
                              <div className="d-grid">
                                <div
                                  className={` d-flex justify-content-between ${styles.nav_mb}  px-2`}
                                >
                                  <div key={`default-1`} className="mb-3">
                                    {filtersData[val].values.map(
                                      (item: any, index: any) => (
                                        <Form.Check
                                          key={`${item.label}-${index}`}
                                          className={`${styles.checkbox}`}
                                          onChange={(e) =>
                                            handleCheckboxChange(e, val, index)
                                          }
                                          name={val}
                                          checked={item.isChecked}
                                          type={filtersData[val].inputType}
                                          id={`${item.label}-${index}`}
                                          label={`${item.label === 0
                                            ? "No"
                                            : item.label === 1
                                              ? "Yes"
                                              : item.label
                                            }${filtersData[val].unit !== null
                                              ? ` ${filtersData[val].unit}`
                                              : ""
                                            } (${item.count})`}
                                        />
                                      )
                                    )}
                                  </div>
                                </div>
                                {
                                  <p
                                    key={i}
                                    onClick={() => {
                                      seeMoreFilters("independent", val);
                                    }}
                                    className="link fs-14 text-start ms-2 mb-0 cursor-pointer"
                                  >
                                    see more
                                  </p>
                                }
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        )}

                        {val === "price" && (
                          <Accordion.Item
                            eventKey={filtersData?.price ? "4" : ""}
                          >
                            <Accordion.Header
                              className={`${styles.nav_header}`}
                            >
                              <div className="d-flex justify-content-between w-100">

                                Prices{" "}
                                {filtersData?.price?.values?.some(
                                  (range: any) => range.isSelected || maxPrice || minPrice
                                ) && (
                                    <span
                                      className="ms-2 secondary-link text-capitalize text-decoration-underline w-100"
                                      onClick={() => {
                                        setPriceRange({});
                                        setMaxPrice('');
                                        setMinPrice('');
                                      }}
                                    >
                                      <Image
                                        className="float-end me-2"
                                        src={filterClearIcon}
                                        alt="clearIcon"
                                        width={18}
                                        height={18}
                                      />
                                    </span>
                                  )}
                              </div>
                            </Accordion.Header>
                            <Accordion.Body>
                              <div className="d-grid position-relative justify-content-center">
                                <div
                                  className={` d-flex justify-content-between ${styles.nav_mb} mb-4 `}
                                ></div>
                                <Button
                                  onClick={() => {
                                    handlePriceChange(10000, 20000);
                                  }}
                                  variant="outline-secondary "
                                  className="mt-2"
                                >
                                  Rs. 10,000 to Rs. 20,000
                                </Button>
                                <Button
                                  onClick={() => {
                                    handlePriceChange(20000, 30000);
                                  }}
                                  variant="outline-secondary mt-2"
                                >
                                  Rs. 20,000 to Rs. 30,000
                                </Button>
                                <Button
                                  onClick={() => {
                                    handlePriceChange(30000, 40000);
                                  }}
                                  variant="outline-secondary mt-2"
                                >
                                  Rs. 30,000 to Rs. 40,000
                                </Button>
                                <Button
                                  onClick={() => {
                                    handlePriceChange(40000, 50000);
                                  }}
                                  variant="outline-secondary mt-2"
                                >
                                  Rs. 40,000 to Rs. 50,000
                                </Button>
                                <Form noValidate validated={validate} onSubmit={handleSubmitPrice}>
                                  <Row className="align-items-center mt-3">
                                    <Col xs="12" className="d-flex">
                                      <Form.Control
                                        type="number"
                                        placeholder="From"
                                        className="rounded-0 rounded-start "
                                        isInvalid={minPrice > maxPrice}
                                        value={minPrice}
                                        min={1}
                                        onChange={(e) =>
                                          setMinPrice(e.target.value)
                                        }
                                      />
                                      <Form.Control
                                        type="number"
                                        className="border-start-0  rounded-0"
                                        placeholder="To"
                                        value={maxPrice}
                                        isInvalid={minPrice > maxPrice}

                                        min={minPrice ? minPrice : 1}
                                        onChange={(e) =>
                                          setMaxPrice(e.target.value)
                                        }
                                      />
                                      <Button
                                        type="submit"
                                        variant="outline-secondary rounded-0 rounded-end btn-sm"

                                      >
                                        Apply
                                      </Button>
                                    </Col>
                                  </Row>
                                </Form>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        )}
                      </React.Fragment>
                    ))}
                </Accordion>
                <div className="mt-3">
                  <Accordion
                    alwaysOpen
                    className={`${styles.accordian_main} `}
                    defaultActiveKey={["0", "1", "2", "3"]}
                  >
                    {dependentFilters &&
                      Object.keys(dependentFilters).map((val: any, i: any) => (
                        <React.Fragment key={i}>
                          {dependentFilters[val].values.length > 0 && (
                            <Accordion.Item
                              key={i}
                              eventKey={
                                dependentFilters[val].values.filter(
                                  (item: any) => item.isChecked === true
                                ).length > 0
                                  ? "0"
                                  : i
                              }
                            >
                              <Accordion.Header
                                className={`${styles.nav_header} `}
                              >
                                <div className="d-flex justify-content-between w-100">

                                  {dependentFilters[val].label
                                    .toString()
                                    .replaceAll("_", " ")
                                    .replace(/\b\w/g, (c) =>
                                      c.toUpperCase()
                                    )}{" "}
                                  {dependentFilters[val]?.values?.some(
                                    (item: any) => item.isChecked
                                  ) && (
                                      <span
                                        className="ms-2 secondary-link text-capitalize text-decoration-underline w-100"
                                        onClick={() => {
                                          clearDependentFilter(val);
                                        }}
                                      >
                                        <Image
                                          className="float-end me-2"
                                          src={filterClearIcon}
                                          alt="clearIcon"
                                          width={18}
                                          height={18}
                                        />
                                      </span>
                                    )}
                                </div>
                              </Accordion.Header>
                              <Accordion.Body>
                                <div className="d-grid">
                                  <div
                                    className={` d-flex justify-content-between ${styles.nav_mb}  px-2`}
                                  >
                                    <div key={`default-1`} className="mb-3">
                                      {dependentFilters[val].values.map(
                                        (item: any, index: any) => (
                                          <Form.Check
                                            key={`${item.label}-${index}`}
                                            className={`${styles.checkbox}`}
                                            onChange={(e) =>
                                              handleChangeDependentFilter(
                                                e,
                                                val,
                                                index
                                              )
                                            }
                                            name={val}
                                            checked={item.isChecked}
                                            type={
                                              dependentFilters[val].inputType
                                            }
                                            id={`${item.label}-${index}`}
                                            label={`${item.label === 0
                                              ? "No"
                                              : item.label === 1
                                                ? "Yes"
                                                : item.label
                                              }${dependentFilters[val].unit !==
                                                null
                                                ? ` ${dependentFilters[val].unit}`
                                                : ""
                                              } (${item.count})`}
                                          />
                                        )
                                      )}
                                    </div>
                                  </div>
                                  {
                                    <p
                                      onClick={() => {
                                        seeMoreFilters("depenedent", val);
                                      }}
                                      className="link fs-14 text-start ms-2 cursor-pointer"
                                    >
                                      see more
                                    </p>
                                  }
                                </div>
                              </Accordion.Body>
                            </Accordion.Item>
                          )}
                        </React.Fragment>
                      ))}
                  </Accordion>
                </div>
              </Offcanvas.Body>
            </div>
          </Offcanvas>
        </div>
      </div>
    </div>
  );
};

export default SideFilters;
